$(document).ready(function() {
    $('.contact-form')
    .add('.newsletter-form')
        .on('submit', function(e) {
            e.preventDefault();

            var $this = $(this);
            var $submitBtn = $this.find('[type=submit]');
            var submitBtnLabel = $submitBtn.html();

            $.ajax({
                type: $this.attr('method'),
                url: $this.attr('action'),
                data: new FormData($this[0]),
                cache: false,
                processContent: false,
                contentType: false,
                processData: false,
                dataType: false,
                beforeSend: function() {
                    $submitBtn.prop('disabled', true);
                    $submitBtn.html('<i class="ri ri-loader-2-line ri--spin"></i>');
                },
                success: function(res) {
                    var response = JSON.parse(res);

                    new NotifyIt({
                        // Message to display.
                        'message': response[1],
                        // Status of the displayed message.
                        'status': (response[0]) ? 'success' : 'error',
                        // Duration of the alert. 0 to keep it.
                        'duration': 5
                    });

                    if (response[0]) {
                        $this[0].reset();
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    new NotifyIt({
                        // Message to display.
                        'message': errorThrown,
                        // Status of the displayed message.
                        'status': 'error',
                        // Duration of the alert. 0 to keep it.
                        'duration': 5
                    });
                },
                complete: function() {
                    $submitBtn.prop('disabled', false);
                    $submitBtn.html(submitBtnLabel);
                }
            });
        });

    $('.js-mobile-nav-open-btn').on('click', function(e) {
        e.preventDefault();

        var $this = $(this);

        if ($('body').hasClass('__mobile-nav-opened')) {
            $('body').removeClass('__mobile-nav-opened');
            $this.html('<i class="ri ri-menu-3-line"></i>');
            $('.mobile-header__bottom').slideUp();
        } else {
            $('body').addClass('__mobile-nav-opened');
            $this.html('<i class="ri ri-close-line"></i>');
            $('.mobile-header__bottom').slideDown();
        }
    });

    var servicesSwiper = new Swiper('.swiper-container--services', {
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        speed: 800,
        autoplay: {
            delay: 3000,
        },
        pagination: {
            el: '.swiper-pagination--services',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            769: {
                slidesPerView: 'auto',
                spaceBetween: 50,
            },
        },
    });

    if ($('.swiper-container--services').length) {
        $('.swiper-container--services').hover(function() {
            servicesSwiper.autoplay.stop();
        }, function() {
            servicesSwiper.autoplay.start();
        });
    }

    var clientsSwiper = new Swiper('.swiper-container--clients', {
        slidesPerView: 2,
        spaceBetween: 50,
        loop: true,
        speed: 900,
        autoplay: {
            delay: 2000,
        },
        breakpoints: {
            480: {
                slidesPerView: 4,
                spaceBetween: 70,
            },
            786: {
                slidesPerView: 5,
                spaceBetween: 100,
            },
        },
    });

    if ($('.swiper-container--clients').length) {
        $('.swiper-container--clients').hover(function() {
            clientsSwiper.autoplay.stop();
        }, function() {
            clientsSwiper.autoplay.start();
        });
    }

    if ($('.__typed').length) {
        var __typed1 = new Typed('.__typed--1', {
            strings: [
                'Design gráfico<span style="color: #8d98a1;">&amp;</span><br>Web design<span style="color: #8d98a1;">&amp;</span><br>Mídias sociais<span style="color: #8d98a1;">&amp;</span><br><span style="color: #00cac9;">AEVO Design.</span>',
                'Café<span style="color: #8d98a1;">&amp;</span><br>Altas horas<span style="color: #8d98a1;">&amp;</span><br>Linhas de código<span style="color: #8d98a1;">&amp;</span><br><span style="color: #00cac9;">AEVO Design.</span>',
                'Illustrator<span style="color: #8d98a1;">&amp;</span><br>Photoshop<span style="color: #8d98a1;">&amp;</span><br>arquivo_v297.psd<span style="color: #8d98a1;">&amp;</span><br><span style="color: #00cac9;">AEVO Design.</span>',
                'Instagram<span style="color: #8d98a1;">&amp;</span><br>Mais curtidas<span style="color: #8d98a1;">&amp;</span><br>Rumo aos 10k<span style="color: #8d98a1;">&amp;</span><br><span style="color: #00cac9;">AEVO Design.</span>'
            ],
            loop: true,
            typeSpeed: 20,
            backSpeed: 20,
            backDelay: 3000,
            startDelay: 0,
            fadeOut: true,
            showCursor: false,
        });
    }

    $(window).on('scroll', function() {
        if ($(this).scrollTop() > 10) {
            $('body').addClass('__scrolled');
        } else {
            $('body').removeClass('__scrolled');
        }
    });

    if ($(window).scrollTop() > 10) {
        $('body').addClass('__scrolled');
    } else {
        $('body').removeClass('__scrolled');
    }
});

$(window).on('load', function() {
    var portfolioHome = $('.portfolio--home').isotope({
        // options
        itemSelector: '.project',
        layoutMode: 'masonry'
    });

    var portfolio = $('.portfolio').isotope({
        // options
        itemSelector: '.project',
        layoutMode: 'masonry'
    });

    $('.filters-button-group').on('click', 'button', function() {
        var filterValue = $(this).attr('data-filter');
        portfolio.isotope({
            filter: filterValue
        });
    });

    $('.filters-button-group').each(function(i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);

        $buttonGroup.on('click', 'button', function() {
            $buttonGroup.find('.is-checked').removeClass('is-checked');
            $(this).addClass('is-checked');
        });
    });
});
